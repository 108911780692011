<template>
  <div class="search-bar-urgent">
    <TopNav
      :navList="permissionNavList"
      :isSubNav="true"
      v-model="searchTab"
      @goTo="selectTab"
    />
    <div class="search-bar">
      <div v-if="searchTab === 'chased'" class="action-cause">
        <div class="title">{{ $t('search_cause') }}<span class="required"> *</span></div>
        <SelectInput v-model.trim="queryCause" :options="getInputOptions(queryCauses)" :placeholder="$t('search_cause_placehold')" />
      </div>
      <div v-if="searchTab === 'chased'" class="divider"></div>
      <RadioSelect v-model="queryType" :options="queryTypeOptions" class="query-type" />
      <TreeSelect 
        class="device" 
        v-model="selectedUsers" 
        :title="accountTitle"
        :data="userTreeData" 
        :groupList="groupList"  
        :isGroupSelect="true"
        :isMultipleSelect="true"
        optionWidth="150%"
      />
      <TreeSelect 
        class="device" 
        v-model="selectedGroup" 
        :title="groupTitle"
        :data="groupTreeData" 
        :groupList="groupList"  
        :isGroupSelect="true"
        :isMultipleSelect="false"
        :defaultProps="deviceGroupProps"
        optionWidth="150%"
      />
    
      <DateRangeSelect 
        class="dt-select" 
        v-model="urgentDateTime" 
        :range="true"
        :title="$t('search_time_interval')" 
        :maxRangeDays="maxRangeDays"
        :enabledDateStart="enabledDateStart" 
        :enabledDateEnd="enabledDateEnd" 
        :shortcuts="shortcuts"
      />
      
      <MagnifierSearchBtn 
        :active="searchLoading"
        @click="searchUrgEvents()" 
      />
      <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('search_clear')/*清除*/" placement="bottom">
        <div class="tool" @click="clearSearchParams">
          <img src="@/assets/icons/erase-white.svg" alt="">
        </div>
      </el-tooltip> 
    </div>
    <Pagination
      class="pagination"
      :currentPage="urgCurrentPage"
      :total="urgEventsTotal"
      :pageSize="urgPageSize" 
      mode="single"
      @getPageData="getPageEvents"
    ></Pagination>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { getUrgentDateRangeSetting } from '@/config/dateRange.js'

export default {
  name: 'SearchBarUrgent',
  components: {
    TopNav: () => import('@/components/tools/TopNav.vue'),
    SelectInput: () => import('@/components/History/SearchBar/SelectInput.vue'),
    TreeSelect: () => import('@/components/Base/TreeSelect.vue'),
    RadioSelect: () => import('@/components/History/SearchBar/RadioSelect.vue'),
    DateRangeSelect: () => import('@/components/Base/DateRangeSelect.vue'),
    MagnifierSearchBtn: () => import('@/components/Base/MagnifierSearchBtn.vue'),
    Pagination: () => import('@/components/Base/Pagination.vue'),
  },
  data() {
    return {
      queryCause: '', // 查詢案由
      queryType: 'device', // 查詢類型: device: 設備, link: 勾稽使用者
      queryTypeOptions: [
        { label: 'search_device' /*設備*/, value: 'device' },
        { label: 'history_link_user' /*勾稽使用者*/, value: 'link' },
      ],
      selectedUsers: [],
      urgentDateTime: [], // 緊急事件時間區間
      maxRangeDays: 90, // 最大可選擇區間天數
      enabledDateStart: null, // 可以查詢開始時間
      enabledDateEnd: null, // 可以查詢結束時間
      shortcuts: [ // 快速選擇時間區間
        { text: this.$t('today'), onClick: () => {
            // 今天 00:00 ~ 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate())
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [ startOfDay, endOfDay ]
          }
        },
        { text: this.$t('yesterday'), 
          onClick: () => {
            // 昨天 00:00 ~ 昨天 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 23, 59, 59)
            return [ startOfDay, endOfDay ]
          }
        },
        { text: this.$t('last_week'), 
          onClick: () => {
            // 7天前 00:00 ~ 今天 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [ startOfDay, endOfDay ]
          }
        },
        { text: this.$t('last_month'), 
          onClick: () => {
            // 30天前 00:00 ~ 今天 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 29)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [ startOfDay, endOfDay ]
          }
        },
        { text: this.$t('last_three_months'), 
          onClick: () => {
            // 過去三個月 00:00 ~ 今天 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 89)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [ startOfDay, endOfDay ]
          }
        }
      ], 

      selectedGroup: {}, // 選中的群組
      deviceGroupList: [], // 設備群組列表
      userGroupList: [], // 使用者群組列表
      deviceGroupProps: {
        children: 'children',
        label: 'label',
      },

      urgentCurrentPage: 1, // 緊急事件當前頁碼
      urgentEventsTotal: 100, // 緊急事件總數
      urgentPageSize: 10, // 緊急事件每頁顯示數量
    }
  },
  computed: {
    ...mapState('account', ['groupList', 'deviceTreeList', 'userTreeList']),
    ...mapState('history', ['queryCauses']),
    ...mapState('historyUrgent', [
      'urgMode',
      'searchLoading', 
      'urgPageSize',
      'urgEventsTotal',
      'urgCurrentPage',
    ]),
    accountTitle() {
      if (this.queryType === 'device') return this.$t('search_device') /*設備*/
      else return this.$t('history_link_user') /*勾稽使用者*/
    },
    groupTitle() {
      if (this.queryType === 'device') return this.$t('history_device_group') /*設備群組*/
      else return this.$t('history_user_group') /*使用者群組*/
    },
    permissionNavList() {
      const list = [
        {
          navTitle: 'event_chasing' /*圍捕*/,
          comp: 'chased',
        },
        {
          navTitle: 'event_sos',
          comp: 'sos',
        },
      ]
      return list
    },
    searchTab: {
      get() {
        return this.urgMode
      },
      set(val) {
        this.updateUrgMode(val)
      },
    },
    userTreeData() {
      return this.queryType === 'device' ? 
        this.deviceTreeList : this.userTreeList
    }, 
    groupTreeData() {
      return this.queryType === 'device' ? 
        this.deviceGroupList : this.userGroupList
    },
  },
  watch: {
    urgMode() {
      this.clearSearchParams()
      this.initDateRange()
    },
  },
  async mounted() {
    // 0: private, 1: public
    await this.getDeviceTreeList({ public: 0 })
    await this.getUserTreeList({ public: 0 })
    
    const deviceData = JSON.parse(JSON.stringify(this.deviceTreeList))
    const userData = JSON.parse(JSON.stringify(this.userTreeList))
    this.deviceGroupList = this.removeEmptyChildren(deviceData)
    this.userGroupList = this.removeEmptyChildren(userData)

    this.initDateRange()
  },
  methods: {
    ...mapMutations('historyUrgent', ['updateUrgMode', 'updateSearchParams']),
    ...mapActions('account', ['getDeviceTreeList', 'getUserTreeList']),
    ...mapActions('historyUrgent', ['searchUrgentEvents', 'resetHistoryUrgData']),
    getInputOptions(list) {
      return list.map(item => {
        return {
          label: item,
          value: item
        }
      })
    },
    initDateRange() {
      // 緊急事件預設搜尋區間: 預設1週
      // 緊急事件最大可搜尋區間: 預設最近三個月
      // 緊急事件最多可搜尋的範圍: 預設最近半年
      const { defaultDateRange, maxRangeDays, enabledDateStart, enabledDateEnd } = getUrgentDateRangeSetting()
      this.urgentDateTime = defaultDateRange
      this.maxRangeDays = maxRangeDays
      this.enabledDateStart = enabledDateStart
      this.enabledDateEnd = enabledDateEnd
    },
    sortflattenTree(obj) {
      if (obj.group) {
        let flattened = { ...obj.group }
        if (obj.children) {
          let sortChildren = obj.children.sort((a, b) => a.group.name.localeCompare(b.group.name))
          flattened.children = sortChildren.map(child => this.sortflattenTree(child))
        }
        return flattened
      } else {
        return obj
      }
    },
    selectTab(tab) {
      this.searchTab = tab
      this.clearSearchParams()
      this.resetHistoryUrgData()
    },
    checkSearchPurpose() {
      if (this.queryCause.length === 0 || this.queryCause.length > 30) {
        let msg = this.queryCause.length === 0 ? this.$t('search_hint_reason') : this.$t('search_hint_reason_length')
        this.$notify({ title: this.$t('search_hint'), message: msg, type: 'warning' })
        return false
      }
      return true
    },
    getSearchParams() {
      const param = {}
      if (this.urgMode === 'chased') {
        // 圍捕需要加上查詢案由
        param.purpose = this.queryCause

        if (this.queryType === 'device') {
          // 設備
          if (this.selectedUsers.length > 0) {
            param.user = this.selectedUsers.map(item => item.id) 
          }

          // 設備群組
          if (this.selectedGroup.groupId) {
            param.group = [this.selectedGroup.groupId]
          }
        } else {
          if (this.selectedUsers.length > 0)
            param.linkedUserIds = this.selectedUsers.map(item => item.index)
          if (this.selectedGroup.groupId) {
            param.group = [this.selectedGroup.groupId]
          }
        }
      } else if (this.urgMode === 'sos') {
        if (this.queryType === 'device') {
          if (this.selectedUsers.length > 0) {
            param.userIds = this.selectedUsers.map(item => item.index) // { id: "annie_dev", index: "1093", ....}
          }

          if (this.selectedGroup.groupId) {
            param.groupIds = [this.selectedGroup.groupId]
          }
        } else {
          // 勾稽使用者
          if (this.selectedUsers.length > 0)
            param.linkedUserIds = this.selectedUsers.map(item => item.index)

          // 勾稽使用者群組
          if (this.selectedGroup.groupId)
            param.groupIds = [this.selectedGroup.groupId]
        }
      }

      // 時間區間
      const timeParams = {
        chased: { start: 'afterDetectTime', end: 'beforeDetectTime' },
        sos: { start: 'afterStartTime', end: 'beforeEndTime' },
      }
      if (this.urgentDateTime.length == 2) {
        const timeKey = timeParams[this.urgMode]
        param[timeKey.start] = new Date(this.urgentDateTime[0]).toISOString()
        param[timeKey.end] = new Date(this.urgentDateTime[1]).toISOString()
      }
      
      return param
    },
    searchUrgEvents() {
      if (this.searchTab === 'chased' && this.checkSearchPurpose() === false) return

      const params = this.getSearchParams()
      this.updateSearchParams(params)
      
      this.searchUrgentEvents({ page: 1 })
    },
    clearSearchParams() {
      this.queryCause = ''
      this.selectedUsers = []
      this.selectedGroup = {}
      this.urgentDateTime = []
      this.initDateRange()
    },
    getPageEvents(page) {
      this.urgentCurrentPage = page
    },
    removeEmptyChildren(data) {
      return data.filter(node => {
        // 節點有children且長度不為0
        if (node.children && node.children.length > 0) {
            node.children = this.removeEmptyChildren(node.children)
            return true
        }
        return node.children
      })
    }
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.search-bar-urgent {
  width: 100%;
  height: 168px;
  color: #ffffff;
  background: #282942;
  border-bottom: 1px solid #ffffff33;

  .search-bar {
    display: flex;
    align-items: flex-end;
    column-gap: px2rem(12);
    padding: px2rem(8) px2rem(20);

    .action-cause {
      width: 16%;
    }

    .divider {
      width: 1px;
      height: 60px;
      background: #ffffff80;
      margin: 0 6px;
    }

    .title {
      @include font_style(px2rem(14), px2rem(21));
      margin-bottom: 4px;
    }

    .required {
      color: #FFC600;
    }
    
    .query-type {
      max-width: 160px;
    }
    .device {
      width: 20%;
    }

    .dt-select {
      width: 25%;
    }
  }
}

.wrap-btn-search {
  display: flex;
  align-items: flex-end;
}

.tool {
  width: px2rem(36);
  height: px2rem(36);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #FFFFFF1A;
    border-radius: 8px;
  }
  &:active {
    background: #FFFFFF80;
  }

  img {
    width: px2rem(22);
    height: px2rem(22);
  }
}

.pagination {
  padding: 8px 20px 10px 20px;
}
</style>